import {useMemo, useRef, useState} from "react";
import {StyleSheet, View, Text} from "react-native";
import MapView, {Marker} from "react-native-maps";
import {MarkerClusterer} from "@teovilla/react-native-web-maps";

import Avatar from "../components/Avatar";

const Map = props => {
  const mapRef = useRef();

  const [region, setRegion] = useState();

  const loadingFallback = useMemo(() => {
    return (
      <View>
        <Text>Loading...</Text>
      </View>
    );
  }, []);

  const OtherUsersOnMap = Object.keys(props.otherUsersLocationInfo).map(key => {
    return (
      <Marker
        key={key}
        coordinate={{
          latitude: props.otherUsersLocationInfo[key].message.coords.latitude,
          longitude: props.otherUsersLocationInfo[key].message.coords.longitude,
        }}>
        <Avatar
          name={props.otherUsersLocationInfo[key].avatar}
          style={{height: 32, width: 32}}
        />
      </Marker>
    );
  });

  return (
    <View style={styles.map}>
      <MapView
        ref={mapRef}
        provider="google"
        style={{flex: 1}}
        onRegionChange={setRegion}
        initialRegion={props.initialRegion}
        loadingFallback={loadingFallback}
        googleMapsApiKey={"AIzaSyBUw67NcFju-4MHsnnZhU9JgR2p90lwqeg"}>
        <MarkerClusterer
          region={region}
          renderCluster={cluster => (
            <MyClusterComponent
              {...cluster}
              onPress={() =>
                mapRef.current?.animateCamera({
                  center: cluster.coordinate,
                  zoom: cluster.expansionZoom + 3,
                })
              }
            />
          )}>
          <Marker
            coordinate={{
              latitude: props.myCoords?.latitude,
              longitude: props.myCoords?.longitude,
            }}>
            <Avatar name={props.myAvatar} style={{height: 32, width: 32}} />
          </Marker>
        </MarkerClusterer>
        <MarkerClusterer region={region}>{OtherUsersOnMap}</MarkerClusterer>
      </MapView>
    </View>
  );
};

const styles = StyleSheet.create({
  map: {
    width: "100%",
    height: "100%",
  },
});

export default Map;
