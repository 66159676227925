import React, {useContext, useState, useEffect} from "react";
import {
  SafeAreaView,
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Linking,
  Platform,
} from "react-native";
import Toast from "react-native-toast-message";

import Map from "../components/Map";
import {SocketContext} from "../util/SocketContext";
import {AppContext} from "../util/AppContext";

const MapScreen = () => {
  const {socketData} = useContext(SocketContext);
  const {data, systemPermissions, otherUsersLocationInfo} =
    useContext(AppContext);

  // TODO: remove if you can get away with using directly from context
  const [coords, setCoords] = useState(data?.myLocation?.coords);
  const [initialRegion, setInitialRegion] = useState(false);

  useEffect(() => {
    setCoords(data?.myLocation?.coords);
    if (
      !initialRegion &&
      data?.myLocation?.coords.latitude &&
      data?.myLocation?.coords.longitude
    ) {
      setInitialRegion({
        latitude: data?.myLocation?.coords.latitude,
        longitude: data?.myLocation?.coords.longitude,
        latitudeDelta: 0.0922,
        longitudeDelta: 0.0421,
      });
    }
  }, [data?.myLocation]);
  // -----------------------------------------------------------

  return (
    <SafeAreaView style={styles.container}>
      {systemPermissions?.systemLocationGranted && initialRegion ? (
        <Map
          initialRegion={initialRegion}
          myCoords={coords}
          myAvatar={socketData.avatar || ""}
          otherUsersLocationInfo={otherUsersLocationInfo}
        />
      ) : (
        <View style={{flex: 1, alignItems: "center", justifyContent: "center"}}>
          {!systemPermissions?.systemLocationGranted && (
            <View>
              <View style={styles.rowInline}>
                <Text>
                  {" "}
                  This app requires location access for this feature. Please
                  click below to access your app location settings and please
                  turn on:{" "}
                </Text>
              </View>
              <View style={styles.rowblock}>
                <TouchableOpacity
                  style={styles.button}
                  onPress={() => {
                    if (Platform.OS === "ios") {
                      Linking.openURL("app-settings:");
                    } else if (Platform.OS === "android") {
                      Linking.openSettings();
                    } else if (Platform.OS === "web") {
                      if (navigator.userAgent.indexOf("Chrome") !== -1) {
                        window.open(
                          "https://support.google.com/chrome/answer/142065",
                          "_blank",
                        );
                      } else if (navigator.userAgent.indexOf("Safari") !== -1) {
                        window.open(
                          "https://support.apple.com/guide/mac-help/allow-apps-to-detect-the-location-of-your-mac-mh35873/mac",
                          "_blank",
                        );
                      }
                    } else {
                      Toast.show({
                        topOffset: 20,
                        autoHide: false,
                        type: "configError",
                        text1: "Sorry, you are not on a supported platform.",
                        onPress: () => {
                          Toast.hide();
                        },
                      });
                    }
                  }}>
                  <Text>Press Here</Text>
                </TouchableOpacity>
              </View>
              <View style={{marginTop: 30}}></View>
            </View>
          )}
        </View>
      )}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  rowInline: {
    flexDirection: "row",
    width: "90%",
    alignItems: "center",
    marginBottom: 35,
  },
  button: {
    alignItems: "center",
    backgroundColor: "#DDDDDD",
    padding: 10,
  },
});

export default MapScreen;
