import React, {useContext} from "react";
import {createBottomTabNavigator} from "@react-navigation/bottom-tabs";
import {NavigationContainer} from "@react-navigation/native";

import StartupScreen from "../screens/StartupScreen";
import SettingsScreen from "../screens/SettingsScreen";
import ChannelMembersScreen from "../screens/ChannelMembersScreen";
import MapScreen from "../screens/MapScreen";
import TabBar from "./TabBar";

import {SocketContext} from "../util/SocketContext";

const Tab = createBottomTabNavigator();

const TabNavigator = () => {
  const {isConnected} = useContext(SocketContext);

  let elementsToRender = <StartupScreen />;

  if (isConnected) {
    elementsToRender = (
      <NavigationContainer>
        <Tab.Navigator
          initialRouteName="Map"
          tabBar={props => <TabBar {...props} />}
          screenOptions={{headerShown: false}}>
          <Tab.Screen
            name="Map"
            component={MapScreen}
            options={{
              iconName: "map-outline",
              tabBarType: "nav",
              tabBarLabel: "Map",
            }}
          />
          <Tab.Screen
            name="Members"
            component={ChannelMembersScreen}
            options={{
              iconName: "people-outline",
              tabBarType: "nav",
              tabBarLabel: "Members",
            }}
          />
          <Tab.Screen
            name="Settings"
            component={SettingsScreen}
            options={{
              iconName: "cog-outline",
              tabBarType: "nav",
              tabBarLabel: "Settings",
            }}
          />
        </Tab.Navigator>
      </NavigationContainer>
    );
  }

  return elementsToRender;
};

export default TabNavigator;
