import React, {useEffect} from "react";
import {AppState, View, TouchableOpacity, Text} from "react-native";
import Toast from "react-native-toast-message";

import SocketProvider from "./util/SocketContext";
import AppContextProvider from "./util/AppContext";

import TabNavigator from "./components/TabNavigator";

const toastConfig = {
  configError: ({text1, onPress}) => (
    <View
      style={{
        width: "95%",
        backgroundColor: "tomato",
        padding: 15,
      }}>
      <TouchableOpacity onPress={onPress}>
        <Text>{text1}</Text>
      </TouchableOpacity>
    </View>
  ),
};

const App = () => {
  useEffect(() => {
    const handleAppStateChange = nextAppState => {
      // Do things app state changes
      // console.log("nextAppState", nextAppState);
    };

    AppState.addEventListener("change", handleAppStateChange);

    return () => {
      AppState.removeEventListener("change", handleAppStateChange);
    };
  }, []);

  return (
    <SocketProvider>
      <AppContextProvider>
        <TabNavigator />
        <Toast config={toastConfig} />
      </AppContextProvider>
    </SocketProvider>
  );
};

export default App;
