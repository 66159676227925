import React, {useContext, useState, useEffect} from "react";
import {
  SafeAreaView,
  View,
  Text,
  TouchableOpacity,
  Switch,
  StyleSheet,
} from "react-native";

import Avatar from "../components/Avatar";
import {SocketContext} from "../util/SocketContext";
import {AppContext} from "../util/AppContext";

const SettingsScreen = () => {
  const {data, onDataChange} = useContext(AppContext);
  const {socket, socketData} = useContext(SocketContext);

  return (
    <SafeAreaView style={styles.container}>
      <View style={{marginTop: 20}}></View>
      <View style={{flex: 1}}>
        <Avatar
          name={socketData.avatar}
          style={{position: "inherit", width: 125, height: 125}}
        />
      </View>
      <View style={{marginTop: 5}}></View>

      <View style={{flex: 1}}>
        <View style={styles.rowBlock}>
          <Text style={styles.inputLabel}>Username: {socketData.name}</Text>
        </View>

        <View style={styles.rowBlock}>
          <Text style={styles.inputLabel}>
            Current Channel: {socketData.channel}
          </Text>
        </View>
      </View>

      <View style={styles.rowInline}>
        <TouchableOpacity
          onPress={() => {
            socket.disconnect();
          }}
          style={[
            styles.button,
            styles.buttonInline,
            styles.buttonSecondaryAction,
          ]}>
          <Text style={styles.buttonText}>Disconnect</Text>
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 25,
  },
  rowBlock: {width: "90%", marginBottom: 8},
  rowInline: {
    flexDirection: "row",
    width: "90%",
    alignItems: "center",
    marginBottom: 35,
  },
  inputLabel: {fontSize: 16, marginBottom: 8},
  textInputSmall: {
    borderWidth: 1,
    padding: 5,
    width: "100%",
    height: 45,
    fontSize: 14,
  },
  button: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
  },
  buttonInline: {
    backgroundColor: "red",
    flex: 1,
    margin: 5,
    alignItems: "center",
    justifyContent: "center",
  },
  buttonText: {
    color: "#FFFFFF",
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "center",
  },
  buttonPrimaryAction: {
    backgroundColor: "#4CAF50",
  },
  buttonSecondaryAction: {
    backgroundColor: "#f44336",
  },
  buttonDisabled: {
    backgroundColor: "#808080",
  },
});

export default SettingsScreen;
