import React, {useContext, useState, useEffect} from "react";
import {SafeAreaView, ScrollView, StyleSheet} from "react-native";
import Toast from "react-native-toast-message";

import {SocketContext} from "../util/SocketContext";

import MemberCard from "../components/MemberCard";

const ChannelMembersScreen = () => {
  const {socket} = useContext(SocketContext);
  const [channelMemberList, setChannelMemberList] = useState([]);

  useEffect(() => {
    if (socket) {
      socket.on("channelJoined", (channel, nickname) => {
        Toast.show({
          topOffset: 20,
          visibilityTime: 2000,
          type: "info",
          text1: `${nickname} joined channel ${channel} 👋`,
        });
      });

      socket.on("channelLeft", (channel, nickname) => {
        Toast.show({
          topOffset: 20,
          visibilityTime: 2000,
          type: "info",
          text1: `${nickname} left channel ${channel}`,
        });
      });

      socket.on("channelMemberList", channelMemberList => {
        setChannelMemberList(channelMemberList);
      });
    }
  }, [socket]);

  const renderedUsers = channelMemberList.map((item, index) => (
    <MemberCard
      key={`${index}-inner`}
      username={item.username}
      avatar={item.avatar}
      myUser={socket.id === item.socketID}
    />
  ));

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView style={{marginTop: 30}}>{renderedUsers}</ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default ChannelMembersScreen;
