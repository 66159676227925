import React from "react";
import Base64 from "Base64";
import { Image } from "expo-image";
const multiavatar = require("@multiavatar/multiavatar");

const Avatar = (props) => {
  return (
    <Image
      style={props.style}
      source={`data:image/svg+xml;base64,${Base64.btoa(
        multiavatar(props.name ? props.name : "boring")
      )}`}
    />
  );
};

export default Avatar;
