import React, {useContext, useEffect, useState} from "react";
import {
  SafeAreaView,
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
} from "react-native";

import Avatar from "../components/Avatar";
import {SocketContext} from "../util/SocketContext";

const StartupScreen = () => {
  const {
    socket,
    isConnected,
    socketData,
    onSocketDataChange,
    onChannelVisited,
  } = useContext(SocketContext);

  const [connecting, setConnecting] = useState(false);
  const [avatar, setAvatar] = useState(socketData.avatar || "");
  const [name, setName] = useState(socketData.name || "");
  const [channel, setChannel] = useState(socketData.channel || "");

  useEffect(() => {
    setName(socketData.name);
    setAvatar(socketData.avatar);
    setChannel(socketData.channel);
  }, [socketData.name, socketData.avatar, socketData.channel]);

  const renderedChannels = socketData.channels?.map((item, index) => (
    <View key={`${index}-inner`} style={styles.rowInline}>
      <TouchableOpacity
        onPress={() => {
          setChannel(item.channel);
          onSocketDataChange({...socketData, channel: item.channel});
        }}
        style={[styles.button, styles.buttonInline]}>
        <Text style={styles.buttonText}>{item.channel}</Text>
      </TouchableOpacity>
    </View>
  ));

  return (
    <SafeAreaView style={styles.container}>
      <View style={{marginTop: 20}}></View>
      <View>
        <Avatar
          name={avatar}
          style={{position: "inherit", width: 125, height: 125}}
        />
      </View>
      <View style={{marginTop: 5}}></View>

      <View style={styles.rowBlock}>
        <TextInput
          placeholder={"Enter text to generate an avatar..."}
          value={avatar}
          onChangeText={text => {
            setAvatar(text);
            onSocketDataChange({...socketData, avatar: text});
          }}
          style={styles.textInputSmall}
        />
      </View>

      <View style={styles.rowBlock}>
        <Text style={styles.inputLabel}>Username</Text>
        <TextInput
          placeholder={"Please enter a user name..."}
          value={name}
          onChangeText={text => {
            setName(text);
            onSocketDataChange({...socketData, name: text});
          }}
          style={styles.textInputSmall}
        />
      </View>

      <View style={styles.rowBlock}>
        <Text style={styles.inputLabel}>Channel Name</Text>
        <TextInput
          placeholder={"Please enter a channe name..."}
          value={channel}
          onChangeText={text => {
            setChannel(text);
            onSocketDataChange({...socketData, channel: text});
          }}
          style={styles.textInputSmall}
        />
      </View>

      <Text style={[styles.inputLabel, {marginTop: 15}]}>
        Previous Channels
      </Text>
      <ScrollView
        style={styles.channelContainer}
        contentContainerStyle={styles.channelContentContainer}>
        {renderedChannels}
      </ScrollView>

      <View style={styles.rowInline}>
        <TouchableOpacity
          disabled={connecting || !avatar || !name || !channel}
          onPress={() => {
            if (socket && !isConnected) {
              setConnecting(true);
              socket.connect();
              socket.emit("joinChannel", channel, name, avatar);
              onChannelVisited(channel);
            }
          }}
          style={[
            styles.button,
            styles.buttonInline,
            connecting || !avatar || !name || !channel
              ? styles.buttonDisabled
              : styles.buttonPrimaryAction,
          ]}>
          <Text style={styles.buttonText}>
            {connecting ? "Connecting..." : "Connect"}
          </Text>
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 25,
  },
  rowBlock: {width: "90%", marginBottom: 8},
  rowInline: {
    flexDirection: "row",
    width: "90%",
    alignItems: "center",
    marginBottom: 8,
  },
  inputLabel: {fontSize: 16, marginBottom: 8},
  textInputSmall: {
    borderWidth: 1,
    padding: 5,
    width: "100%",
    height: 45,
    fontSize: 14,
  },
  button: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
  },
  buttonInline: {
    backgroundColor: "red",
    flex: 1,
    margin: 5,
    alignItems: "center",
    justifyContent: "center",
  },
  buttonText: {
    color: "#FFFFFF",
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "center",
  },
  buttonPrimaryAction: {
    backgroundColor: "#4CAF50",
  },
  buttonDisabled: {
    backgroundColor: "#808080",
  },
  channelContainer: {
    flex: 1,
    width: "90%",
  },
  channelContentContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
});

export default StartupScreen;
