import React, {createContext, useState, useEffect} from "react";
import Toast from "react-native-toast-message";
import AsyncStorage from "@react-native-async-storage/async-storage";

export const SocketContext = createContext();

const SocketProvider = ({children}) => {
  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [socketData, setSocketData] = useState({});

  useEffect(() => {
    // Load data from AsyncStorage when component mounts
    AsyncStorage.getItem("mySocketData").then(savedData => {
      setSocketData(savedData ? JSON.parse(savedData) : {});
    });
  }, []);

  useEffect(() => {
    // Save data to AsyncStorage whenever it changes
    AsyncStorage.setItem("mySocketData", JSON.stringify(socketData));
  }, [socketData]);

  const onSocketDataChange = newData => {
    setSocketData(newData);
  };

  const onChannelVisited = newChannel => {
    const newEntry = {
      channel: newChannel,
      timestamp: new Date(),
    };

    const channels = "channels" in socketData ? socketData.channels : [];
    const filteredChannels = channels.filter(
      item => item.channel === newChannel,
    );
    if (filteredChannels.length === 0) {
      channels.push(newEntry);
    }

    setSocketData({...socketData, channels});
  };

  useEffect(() => {
    if (!socket) {
      const socketInstance = {
        on: (event, callback) => {
          console.log("Socket on", event);
        },
        off: event => {
          console.log("Socket off", event);
        },
        emit: (event, data) => {
          console.log("event", event);
          console.log("data", data);
        },
        connect: () => {
          console.log("Socket connected");
          setIsConnected(true);
        },
        disconnect: () => {
          console.log("Socket disconnected");
          Toast.show({
            topOffset: 20,
            type: "error",
            text1: "Disconnected from server",
          });
          setIsConnected(false);
        },
        close: () => {
          console.log("Socket closed");
          Toast.show({
            topOffset: 20,
            type: "error",
            text1: "Disconnected from server",
          });
          setIsConnected(false);
        },
      };

      setSocket(socketInstance);

      return () => socketInstance.close();
    }
  }, [socket]);

  useEffect(() => {
    if (socket) {
      socket.on("error", () => {
        Toast.show({
          topOffset: 20,
          type: "error",
          text1: "Connection Error",
        });
      });

      socket.on("connect_failed", () => {
        Toast.show({
          topOffset: 20,
          type: "error",
          text1: "Connection to server failed",
        });
        setIsConnected(false);
      });
    }
  }, [socket]);

  return (
    <SocketContext.Provider
      value={{
        socket,
        isConnected,
        socketData,
        onSocketDataChange,
        onChannelVisited,
      }}>
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
