import React, {createContext, useState, useEffect, useContext} from "react";
import {AppState} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Location from "expo-location";

import {SocketContext} from "../util/SocketContext";

export const AppContext = createContext();

const AppContextProvider = ({children}) => {
  const {socket, isConnected} = useContext(SocketContext);
  const [data, setData] = useState({});
  const [systemPermissions, setSystemPermissions] = useState({
    systemLocationGranted: false,
  });
  const [otherUsersLocationInfo, setOtherUsersLocationInfo] = useState({});

  /* Handle data storage layer */
  /*---------------------------*/
  useEffect(() => {
    // Load data from AsyncStorage when component mounts
    AsyncStorage.getItem("myData").then(savedData => {
      setData(savedData ? JSON.parse(savedData) : {});
    });
  }, []);

  useEffect(() => {
    // Save data to AsyncStorage whenever it changes
    AsyncStorage.setItem("myData", JSON.stringify(data));
  }, [data]);

  const onDataChange = newData => {
    setData(newData);
  };
  /*---------------------------*/

  /* Handle location layer */
  /*---------------------------*/

  // deal with permissions outside of app
  /*---------------------------*/
  const handleAppStateChange = async nextAppState => {
    if (nextAppState === "active") {
      const {status} = await Location.getForegroundPermissionsAsync();
      if (status === "granted") {
        setSystemPermissions({
          systemLocationGranted: true,
        });
      } else {
        setSystemPermissions({
          systemLocationGranted: false,
        });
      }
    }
  };

  useEffect(() => {
    AppState.addEventListener("change", handleAppStateChange);
    return () => {
      // Remove event listener when component unmounts
      AppState.removeEventListener("change", handleAppStateChange);
    };
  }, []);
  /*---------------------------*/

  // deal with broadcasting my location
  /*---------------------------*/
  useEffect(() => {
    let locationSubscription;

    (async () => {
      if (socket && isConnected) {
        let {status} = await Location.requestForegroundPermissionsAsync();
        if (status !== "granted") {
          setSystemPermissions({
            systemLocationGranted: false,
          });
          onDataChange({
            myLocation: null,
          });
          return;
        }

        setSystemPermissions({
          systemLocationGranted: true,
        });
        locationSubscription = await Location.watchPositionAsync(
          {
            accuracy: Location.Accuracy.High,
            timeInterval: 1500,
            distanceInterval: 5,
          },
          newLocation => {
            onDataChange({
              ...data,
              myLocation: newLocation,
            });
            socket?.emit("locationInfo", newLocation);
          },
        );
      }
    })();

    return () => {
      if (locationSubscription) {
        locationSubscription.remove();
      }
    };
  }, [socket, isConnected]);
  /*---------------------------*/

  // process other user location data
  /*---------------------------*/
  useEffect(() => {
    if (socket) {
      socket.on(
        "locationInfo",
        (socketID, channel, username, avatar, message) => {
          const temp = {...otherUsersLocationInfo};
          temp[socketID] = {
            channel,
            username,
            avatar,
            message,
            timereceived: new Date().getTime(),
          };

          setOtherUsersLocationInfo(prevState => ({
            ...prevState,
            ...temp,
          }));
        },
      );
    }
  }, [socket]);
  /*---------------------------*/

  return (
    <AppContext.Provider
      value={{data, onDataChange, systemPermissions, otherUsersLocationInfo}}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
