import React from "react";
import {View, TouchableOpacity, Text, StyleSheet} from "react-native";
import Icon from "react-native-vector-icons/Ionicons";

const MyTabBar = ({state, descriptors, navigation}) => {
  return (
    <View style={styles.tabNavContainer}>
      {state.routes.map((route, index) => {
        const {options} = descriptors[route.key];
        const isFocused = state.index === index;

        const onPress = () => {
          const event = navigation.emit({
            type: "tabPress",
            target: route.key,
            canPreventDefault: true,
          });

          if (!isFocused && !event.defaultPrevented) {
            navigation.navigate(route.name);
          }
        };

        let elementToReturn = null;

        if (options.tabBarType === "nav") {
          elementToReturn = (
            <TouchableOpacity
              key={route.key}
              onPress={onPress}
              style={styles.tabNavButton}>
              <Icon
                name={options.iconName}
                size={24}
                color={isFocused ? "#007AFF" : "#8E8E93"}
              />
              <Text>{options.tabBarLabel}</Text>
            </TouchableOpacity>
          );
        }

        return elementToReturn;
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  tabNavContainer: {
    flexDirection: "row",
    backgroundColor: "#FFF",
    borderTopWidth: 1,
    borderTopColor: "#E5E5EA",
    elevation: 2,
  },
  tabNavButton: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 12,
  },
});

export default MyTabBar;
