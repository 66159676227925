import React from "react";
import { View, Text, StyleSheet } from "react-native";
import Avatar from "../components/Avatar";

const MemberCard = (props) => {
  return (
    <View style={styles.row} key={`${props.index}-inner`}>
      <View style={styles.userLabelContainer}>
        <Text style={props.myUser ? styles.userLabelMy : styles.userLabelOther}>
          {props.username}
        </Text>
      </View>

      <View>
        <Avatar
          name={props.avatar}
          style={{ position: "inherit", width: 50, height: 50 }}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  userLabelContainer: {
    width: 75,
  },
  userLabelMy: {
    fontWeight: "800",
  },
  userLabelOther: {
    fontWeight: "300",
  },
});

export default MemberCard;
